<template>
  <div>
    <!-- class="flex items-center h-24 px-4" -->
    <vs-card>
      <div class="flex items-center justify-between h-16 px-4">
        <div class="flex items-center">
          <feather-icon
            icon="HashIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            المعرف : {{ user.id }}
          </p>
        </div>

        <div class="flex items-center">
          <feather-icon
            icon="UserIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            المستخدم : {{ user.last_name }}
          </p>
        </div>

        <div class="flex items-center">
          <feather-icon
            icon="MailIcon"
            svg-classes="h-8 w-8"
            class="mr-4 icon_color"
          />
          <p class="brief_color">
            الإيميل : {{ user.email }}
          </p>
        </div>
      </div>
    </vs-card>


    <div class="mt-10">
      <vx-drag-and-drop-multiple-lists
        :list1="projectPermissions"
        :list2="addedPermissions"
        list1title=" كافةالصلاحيات"
        list2title="الصلاحيات المراد إضافتها لهذا المستخدم"
      >
        <permissions-filter @getPermissionsByProject="getPermissionsByProject($event)"/>
      </vx-drag-and-drop-multiple-lists>
    </div>

    <div class="flex items-center justify-center mt-10">
      <vs-button
        :disabled="isLoading"
        type="filled"
        ycolor="success"
        @click.prevent="onSubmit()"
      >
        حفظ التغييرات
      </vs-button>
    </div>
  </div>
</template>

<script>
import {SharedRepositoryFactory} from "@/app/shared/shared-repositories/sharedRepositoryFactory";
import permissionsFilter from "@/app/shared/shared-components/PermissionsFilter";

const permissionRepo = SharedRepositoryFactory.get('permissionRepository');
const userRepo = SharedRepositoryFactory.get('userRepository');

export default {
  name: "UserMain",
  components: {
    permissionsFilter
  },

  data() {
    return {
      user: Object,
      isLoading: true,
      popupActive: false,
      permissions: [],
      addedPermissions: [],
      projectPermissions: [],
      permission: {
        id: null,
        name: '',
        name_ar: '',
        desc: ''
      },
      project: {}
    };
  },

  methods: {
    onSubmit() {
      const ids = this.addedPermissions.map(item => item.id);
      permissionRepo.assignPermissionToUser(ids, this.$route.params.id).then(() => {
        this.fetchAllPermissionsForUser(this.$route.params.id);
        this.isLoading = false;
      });
    },

    fetchAllPermissions() {
      permissionRepo.fetchAllPermissions().then((data) => {
        this.permissions = data.filter(item => !this.addedPermissions.find(permission => (permission.name === item.name)));
        this.getPermissionsByProject(this.project);
      });
    },
    fetchAllPermissionsForUser(userId) {
      userRepo.fetchUserById(userId).then((response) => {
        this.addedPermissions = response.permissions;
        this.user = response;
        this.fetchAllPermissions();
        this.isLoading = false;
      });
    },
    getPermissionsByProject(project) {
      this.project = project;
      this.projectPermissions = [];
      this.permissions.forEach(item => {
        if (item.project_name === project.projectName)
          this.projectPermissions.push(item);
      });
    },
  },
  created() {
    this.project = {projectName: 'default', showName: 'صلاحيات لوحة التحكم'};
    this.fetchAllPermissionsForUser(this.$route.params.id);
  }
};
</script>


<style scoped>
.icon_color {
  color: #4B364E !important;
}

.brief_color {
  color: #888888;
  font-size: 18px;
  font-weight: 600;
}
</style>
